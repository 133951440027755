.p {
  margin-bottom: 1rem;
}

.codigosValesCreditos {
  background-color: #f2f2f2;
  padding: 15px;
  text-align: center;
}

.buttonsValidarCodigos {
  text-align: center;
}

.textAreaObservacao {
  font-weight: 400;
  font-size: 1.5em;
  padding: 4px;
  max-width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: 0;
  margin-left: 2%;
}

.observacaoWidth {
  width: 100%;
}

.observacaoTitle {
  font-size: 17px;
  text-align: center;
}

.observacao {
  background-color: #f2f2f2;
  padding: 15px;
  text-align: center;
}

.sweet-loading {
  z-index: 1000;
  position: fixed;
  margin: auto;
  text-align: center;
  left: 0px;
  right: 0px;
  top: 400px;
}

.container-disabled {
  pointer-events: none;
  opacity: 0.2;
  background: #ccc;
}

.discountSelected {
  background-color: #ffffff;
  border: 2px solid red;
}

.doneSelected {
  background-color: #ffffff;
  border: 2px solid #3785c1;
}

.shippingAddAddress {
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  background-color: #f2f2f2;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 10px;
}

.closeAddShippingAddress {
  position: absolute;
  right: 25px;
  top: 10px;
}

.billingAddAddress {
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  background-color: #f2f2f2;
  transition: all 0.3s linear;
  padding: 10px;
}

.billingModifyAddress {
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  background-color: #f2f2f2;
  transition: all 0.3s linear;
  padding: 10px;
}

.shippingModifyAddress {
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  background-color: #f2f2f2;
  transition: all 0.3s linear;
  padding: 10px;
}

.billingRowTitleAddress {
  margin: 0px;
}

.billingRowTitleAddress button {
  z-index: 2000;
  position: absolute;
  right: 0px;
}

.shippingRowTitleAddress {
  margin: 0px;
}

.shippingRowTitleAddress button {
  z-index: 2000;
  position: absolute;
  right: 0px;
}

.closeModifyBillingAddress {
  position: absolute;
  right: 15px;
  top: 0px;
}

.closeModifyShippingAddress {
  position: absolute;
  right: 15px;
  top: 0px;
}

.titleShippingAddAddress {
  font-size: 20px;
}

.titleBillingAddAddress {
  font-size: 20px;
}

.titleBillingModifyAddress {
  font-size: 20px;
}

.titleShippingModifyAddress {
  font-size: 20px;
}

.is-visible {
  display: block;
}

.is-not-visible {
  display: none;
  opacity: 0;
}

.is-open {
  margin-top: 0px;
  opacity: 1;
}

.adicionarDadosCheckout {
  display: table-cell;
  vertical-align: middle;
  height: 80px;
}

.moradaEnvio {
  width: 100%;
  font-size: 14px;
}

.moradaFaturacao {
  width: 100%;
  font-size: 14px;
}

.inputsMoradasFaturacao {
  position: absolute;
  left: 130px;
  width: 405px;
}

.inputMoradasRow {
  margin: 5px;
  padding: 5px;
}

.addMoradasButton {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.changeButtonBillingAddress {
  /* right: 20px; */
  top: -5px;
}

.removeButtonBillingAddress {
  /* right: 20px; */
  top: -5px;
}

.addButtonBillingAddress {
  /* right: 20px; */
  top: -5px;
}

.changeButtonShippingAddress {
  /* right: 20px; */
  top: -5px;
}

.removeButtonShippingAddress {
  /* right: 20px; */
  top: -5px;
}

.addButtonShippingAddress {
  /* right: 20px; */
  top: -5px;
}

.checkoutBox {
  background-color: #f2f2f2;
  padding: 15px;
}

.disabledBox {
  pointer-events: none;
  opacity: 0.4;
}

.disabledCreditsBox {
  pointer-events: none;
  opacity: 0.4;
}

.disabledCodigoBox {
  pointer-events: none;
  opacity: 0.4;
}

/*-- Checkout Cart Total --*/

.checkout-cart-total {
  background-color: #f2f2f2;
  padding: 45px;
  height: 100%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .checkout-cart-total {
    padding: 30px;
  }
}

.checkout-cart-total h4 {
  -ms-flex-preferred-size: 18px;
  flex-basis: 18px;
  line-height: 23px;
  font-weight: 700;
}

.checkout-cart-total h4:first-child {
  margin-top: 0;
  margin-bottom: 25px;
}

.checkout-cart-total h4:last-child {
  margin-top: 15px;
  margin-bottom: 0;
}

.checkout-cart-total h4 span {
  float: left;
  display: block;
}

.checkout-cart-total ul {
  border-bottom: 1px solid #999999;
}

.checkout-cart-total ul li {
  color: #7a7a7a;
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 16px;
  text-align: left;
}

.checkout-cart-total ul li div {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-align: left;
}

.checkout-cart-total ul li span {
  color: #363f4d;
  float: right;
}

.checkout-cart-total p {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #505050;
  padding: 10px 0;
  border-bottom: 1px solid #999999;
  margin: 0;
}

.promotionalCodeUsedContainer {
  font-size: 14px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.promotionalCodeUsedContainer span {
  margin-left: 2px;
  margin-right: 2px;
}

.vouchersUsedContainer {
  font-size: 14px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}

.vouchersUsedContainer span {
  margin-left: 2px;
  margin-right: 2px;
}

.vouchersAvailableContainer {
  font-size: 14px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.vouchersAvailableContainer span {
  margin-left: 2px;
  margin-right: 2px;
}

.creditsUsedContainer {
  font-size: 14px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}

.creditsUsedContainer span {
  margin-left: 2px;
  margin-right: 2px;
}

.creditsAvailableContainer {
  font-size: 14px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.creditsAvailableContainer span {
  margin-left: 2px;
  margin-right: 2px;
}

.checkout-cart-total p span {
  /*float: left;*/
}

.observacaoBox {
  height: 100%;
}

.SelectField label {
  position: absolute !important;
  line-height: 20px;
  width: 100% !important;
  z-index: 1;
  color: rgba(0, 0, 0, 0.3);
  transform: scale(1) translate(0px, -30px) !important;
  left: 0px !important;
}

.newShippingAddressStyle {
  padding: 10px;
  animation: 0.2s ease-in 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.checkout-cart-total ul li span.removeDiscountButton {
  cursor: pointer;
  margin-right: 5px;
  color: rgb(202, 0, 106);
}

.checkout-cart-total ul li span.removeDiscountButton:hover {
  font-weight: 600;
}

.checkout-cart-total ul li div.discountAbleToRemove {
  color: rgb(202, 0, 106);
  display: block;
}

.checkout-cart-total ul li div.discountAbleToRemove span {
  color: rgb(202, 0, 106);
}

.checkout-cart-total ul li div.hasGlobalDiscount {
  color: rgb(13, 179, 68);
  display: block;
}

.checkout-cart-total ul li div.hasGlobalDiscount span {
  color: rgb(13, 179, 68);
}

.checkoutTitleParent {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}

.checkoutTitle {
  font-family: "Montserrat", sans-serif!important;
  font-weight: 600;
  font-style: normal;
  font-size: 25px;
  color: rgba(71, 73, 79, 1);
}