@import "~bootstrap/dist/css/bootstrap.css";
@import "~cookieconsent/build/cookieconsent.min.css";
body {}

code {}

/* Controlar CSS do container principal */

.page-section {
  margin-top: 7px;
}