.footerColumnContainer {
    font-family: "Rubik", Sans-serif!important;
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    justify-content: flex-start
}

.footerColumnTitle {
    color: white;
    font-family: "Rubik", Sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
}

.footerColumnMenuList {
    line-height: 1.2em;
    margin: 0px;
}

.footerColumnMenuList li {
    padding: 5px 0px 5px 0px;
}

.footerColumnMenuItem {
    color: white;
    font-size: 14px;
    font-weight: 300;
    transition: 0.4s;
}

.footerColumnMenuItem:hover {
    color: #3FBF55;
    text-decoration: none;
}

@media (min-width: 576px) {
    .footerColumnContainer {
        min-width: 100%;
        max-width: 100%;
        justify-content: flex-start
    }
    .footerColumnContainer.columnAlignCenter {
        padding-left: 20px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .footerColumnContainer {
        min-width: 16.6%;
        max-width: 16.6%;
    }
    .footerColumnContainer.columnAlignCenter {
        justify-content: center
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .footerColumnContainer {
        min-width: 16.6%;
        max-width: 16.6%;
        justify-content: flex-start
    }
    .footerColumnContainer.columnAlignCenter {
        justify-content: flex-start
    }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .footerColumnContainer {
        min-width: 14.28%;
        max-width: 14.28%;
        justify-content: flex-start
    }
    .footerColumnContainer.columnAlignCenter {
        justify-content: flex-start
    }
}