.socialMediaContaine {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

a.icons {
    cursor: pointer;
    margin: 7px 10px 7px 10px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 50%;
    background-color: white;
    color: #004579;
    width: 32px;
    height: 32px;
    font-size: 20px;
}