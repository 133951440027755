.justifyCenter {
  text-align: center;
}

.register {
  float: right;
  margin-right: 5%;
}

.lostPassword {
  float: left;
  margin-left: 5%;
}

.title-recover-password {
  font-size: 32px;
  color: #044375;
  font-family: "Source Sans Pro";
}

.email-recover-password {
  border: 1px solid #044375;
  border-radius: 5px;
  font-size: 19px;
  font-family: "Source Sans Pro";
  width: 96vw;
  margin-bottom: 2vh;
  padding: 2vw;
  text-align: center;
}

.button-recover-password {
  background-color: #ca006a;
  border: none;
  color: white;
  font-size: 28px;
  font-family: "Source Sans Pro";
  width: 96vw;
  margin-bottom: 2vh;
  margin-top: 2vh;
}
