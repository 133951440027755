.categorias li {
  color: white;
  background-color: rgba(0, 82, 148, 1);
  border-radius: 10px;
}

.categorias li:hover {
  color: white;
  background-color: rgba(0, 100, 181, 0.8);
}

.categorias a {
  color: white;
}

.bp3-md-grid {
  width: 100%;
  min-width: 1366px;
  height: 100vh;
  min-height: 768px;
  position: relative;
  overflow: hidden;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 1);
}

div {
  -webkit-text-size-adjust: none;
}

.bp3-md-grid-productphoto {
  background-color: rgba(255, 255, 255, 0);
  height: 120px;
  width: 173px;
  margin: 0;
  position: relative;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto .bp3-md-grid-bg {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 120px;
  width: 173px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto .bp3-md-grid-photo {
  background-color: rgba(255, 255, 255, 0);
  top: 51px;
  height: 18px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 69px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart {
  top: 75px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  right: 0px;
}

.bp3-md-grid-addtocart .bp3-md-grid-addtocartbutton {
  top: 0px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  left: 0px;
  background: #ca006a;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  font-family: SourceSansPro-Semibold;
  font-size: 18px;
  color: #ffffff;
}

.bp3-md-grid-addtocart .bp3-md-grid-comprar {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 63px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-ihavethepowerh {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: auto;
  min-width: 466px;
  position: absolute;
  margin: 0;
  left: 201px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(3, 66, 117, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-a1000u20ac {
  background-color: rgba(255, 255, 255, 0);
  top: 46px;
  height: auto;
  position: absolute;
  margin: 0;
  right: 0px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 27px;
}

.bp3-md-grid-labelsquareyes {
  background-color: rgba(255, 255, 255, 0);
  top: 55px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 201px;
  border-radius: 4px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes .bp3-md-grid-rectangle {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes .bp3-md-grid-ud83dudd38color {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes .bp3-md-grid-ud83dudd38color .bp3-md-grid-background {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes .bp3-md-grid-ud83dudd38color .bp3-md-grid-stylescolorsgreen {
  background-color: rgba(89, 181, 141, 1);
  top: 0px;
  height: 21px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes .bp3-md-grid-dispon355vel {
  background-color: rgba(255, 255, 255, 0);
  top: 3px;
  height: auto;
  width: 103px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Rubik", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: -0.4px;
  line-height: 14px;
}

.bp3-md-grid-facebook {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-datas {
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgb(0, 123, 255);
  text-align: left;
  position: absolute;
  left: 201px;
  top: 80px;
}

.bp3-md-grid-facebook .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-facebook .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 5px;
  position: absolute;
  margin: 0;
  left: 8px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube .bp3-md-grid-fill1 {
  background-color: rgba(255, 255, 255, 0);
  top: 7px;
  height: 8px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube .bp3-md-grid-fill2 {
  background-color: rgba(255, 255, 255, 0);
  top: 9px;
  height: 4px;
  width: 3px;
  position: absolute;
  margin: 0;
  left: 9px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram .bp3-md-grid-shape1 {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 10px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram .bp3-md-grid-oval {
  background-color: rgba(255, 255, 255, 0);
  top: 4px;
  height: 3px;
  width: 2px;
  position: absolute;
  margin: 0;
  left: 14px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto1 {
  background-color: rgba(255, 255, 255, 0);
  top: 259px;
  height: 120px;
  width: 173px;
  position: absolute;
  margin: 0;
  left: 483px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto1 .bp3-md-grid-bg {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 130px;
  width: 173px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto1 .bp3-md-grid-photo {
  background-color: rgba(255, 255, 255, 0);
  top: 51px;
  height: 18px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 69px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart1 {
  background-color: rgba(255, 255, 255, 0);
  top: 340px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  left: 477px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart1 .bp3-md-grid-addtocartbutton {
  background-color: rgba(202, 0, 166, 0);
  top: 0px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart1 .bp3-md-grid-comprar {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 63px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-ihavethepowerh1 {
  background-color: rgba(255, 255, 255, 0);
  top: 259px;
  height: auto;
  width: 466px;
  position: absolute;
  margin: 0;
  left: 684px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(3, 66, 117, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-a1000u20ac1 {
  background-color: rgba(255, 255, 255, 0);
  top: 52px;
  height: auto;
  width: 59px;
  position: absolute;
  margin: 0;
  left: 1091px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  color: rgba(3, 66, 117, 1);
  text-align: left;
  line-height: 27px;
}

.bp3-md-grid-labelsquareyes1 {
  background-color: rgba(255, 255, 255, 0);
  top: 303px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 689px;
  border-radius: 4px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes1 .bp3-md-grid-rectangle {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes1 .bp3-md-grid-ud83dudd38color {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes1 .bp3-md-grid-ud83dudd38color .bp3-md-grid-background {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes1 .bp3-md-grid-ud83dudd38color .bp3-md-grid-stylescolorsgreen {
  background-color: rgba(89, 181, 141, 1);
  top: 0px;
  height: 21px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes1 .bp3-md-grid-dispon355vel {
  background-color: rgba(255, 255, 255, 0);
  top: 3px;
  height: auto;
  width: 103px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Rubik", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: -0.4px;
  line-height: 14px;
}

.bp3-md-grid-facebook1 {
  background-color: rgba(255, 255, 255, 0);
  top: 352px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 693px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-facebook1 .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-facebook1 .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 5px;
  position: absolute;
  margin: 0;
  left: 8px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin1 {
  background-color: rgba(255, 255, 255, 0);
  top: 352px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 721px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin1 .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin1 .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube1 {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube1 .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube1 .bp3-md-grid-fill1 {
  background-color: rgba(255, 255, 255, 0);
  top: 7px;
  height: 8px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube1 .bp3-md-grid-fill2 {
  background-color: rgba(255, 255, 255, 0);
  top: 9px;
  height: 4px;
  width: 3px;
  position: absolute;
  margin: 0;
  left: 9px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram1 {
  background-color: rgba(255, 255, 255, 0);
  top: 352px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 777px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram1 .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram1 .bp3-md-grid-shape1 {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 10px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram1 .bp3-md-grid-oval {
  background-color: rgba(255, 255, 255, 0);
  top: 4px;
  height: 3px;
  width: 2px;
  position: absolute;
  margin: 0;
  left: 14px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto2 {
  background-color: rgba(255, 255, 255, 0);
  top: 410px;
  height: 120px;
  width: 173px;
  position: absolute;
  margin: 0;
  left: 483px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto2 .bp3-md-grid-bg {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 120px;
  width: 173px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto2 .bp3-md-grid-photo {
  background-color: rgba(255, 255, 255, 0);
  top: 51px;
  height: 18px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 69px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart2 {
  background-color: rgba(255, 255, 255, 0);
  top: 491px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  left: 965px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart2 .bp3-md-grid-addtocartbutton {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart2 .bp3-md-grid-comprar {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 63px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-ihavethepowerh2 {
  background-color: rgba(255, 255, 255, 0);
  top: 410px;
  height: auto;
  width: 466px;
  position: absolute;
  margin: 0;
  left: 684px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(3, 66, 117, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-a1000u20ac2 {
  background-color: rgba(255, 255, 255, 0);
  top: 448px;
  height: auto;
  width: 59px;
  position: absolute;
  margin: 0;
  left: 1091px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  color: rgba(3, 66, 117, 1);
  text-align: left;
  line-height: 27px;
}

.bp3-md-grid-labelsquareyes2 {
  background-color: rgba(255, 255, 255, 0);
  top: 454px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 689px;
  border-radius: 4px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes2 .bp3-md-grid-rectangle {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes2 .bp3-md-grid-ud83dudd38color {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes2 .bp3-md-grid-ud83dudd38color .bp3-md-grid-background {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes2 .bp3-md-grid-ud83dudd38color .bp3-md-grid-stylescolorsgreen {
  background-color: rgba(89, 181, 141, 1);
  top: 0px;
  height: 21px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes2 .bp3-md-grid-dispon355vel {
  background-color: rgba(255, 255, 255, 0);
  top: 3px;
  height: auto;
  width: 103px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Rubik", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: -0.4px;
  line-height: 14px;
}

.bp3-md-grid-facebook2 {
  background-color: rgba(255, 255, 255, 0);
  top: 503px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 693px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-facebook2 .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-facebook2 .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 5px;
  position: absolute;
  margin: 0;
  left: 8px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin2 {
  background-color: rgba(255, 255, 255, 0);
  top: 503px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 721px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin2 .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin2 .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube2 {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube2 .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube2 .bp3-md-grid-fill1 {
  background-color: rgba(255, 255, 255, 0);
  top: 7px;
  height: 8px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube2 .bp3-md-grid-fill2 {
  background-color: rgba(255, 255, 255, 0);
  top: 9px;
  height: 4px;
  width: 3px;
  position: absolute;
  margin: 0;
  left: 9px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram2 {
  background-color: rgba(255, 255, 255, 0);
  top: 503px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 777px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram2 .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram2 .bp3-md-grid-shape1 {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 10px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram2 .bp3-md-grid-oval {
  background-color: rgba(255, 255, 255, 0);
  top: 4px;
  height: 3px;
  width: 2px;
  position: absolute;
  margin: 0;
  left: 14px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto3 {
  background-color: rgba(255, 255, 255, 0);
  top: 561px;
  height: 120px;
  width: 173px;
  position: absolute;
  margin: 0;
  left: 483px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto3 .bp3-md-grid-bg {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 120px;
  width: 173px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-productphoto3 .bp3-md-grid-photo {
  background-color: rgba(255, 255, 255, 0);
  top: 51px;
  height: 18px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 69px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart3 {
  background-color: rgba(255, 255, 255, 0);
  top: 642px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  left: 965px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart3 .bp3-md-grid-addtocartbutton {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-addtocart3 .bp3-md-grid-comprar {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 63px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-ihavethepowerh3 {
  background-color: rgba(255, 255, 255, 0);
  top: 561px;
  height: auto;
  width: 466px;
  position: absolute;
  margin: 0;
  left: 684px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: rgba(3, 66, 117, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-a1000u20ac3 {
  background-color: rgba(255, 255, 255, 0);
  top: 599px;
  height: auto;
  width: 59px;
  position: absolute;
  margin: 0;
  left: 1091px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  color: rgba(3, 66, 117, 1);
  text-align: left;
  line-height: 27px;
}

.bp3-md-grid-labelsquareyes3 {
  background-color: rgba(255, 255, 255, 0);
  top: 605px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 689px;
  border-radius: 4px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes3 .bp3-md-grid-rectangle {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes3 .bp3-md-grid-ud83dudd38color {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes3 .bp3-md-grid-ud83dudd38color .bp3-md-grid-background {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes3 .bp3-md-grid-ud83dudd38color .bp3-md-grid-stylescolorsgreen {
  background-color: rgba(89, 181, 141, 1);
  top: 0px;
  height: 21px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-labelsquareyes3 .bp3-md-grid-dispon355vel {
  background-color: rgba(255, 255, 255, 0);
  top: 3px;
  height: auto;
  width: 103px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Rubik", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: -0.4px;
  line-height: 14px;
}

.bp3-md-grid-facebook3 {
  background-color: rgba(255, 255, 255, 0);
  top: 654px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 693px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-facebook3 .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-facebook3 .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 5px;
  position: absolute;
  margin: 0;
  left: 8px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin3 {
  background-color: rgba(255, 255, 255, 0);
  top: 654px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 721px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin3 .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-linkedin3 .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube3 {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube3 .bp3-md-grid-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube3 .bp3-md-grid-fill1 {
  background-color: rgba(255, 255, 255, 0);
  top: 7px;
  height: 8px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-youtube3 .bp3-md-grid-fill2 {
  background-color: rgba(255, 255, 255, 0);
  top: 9px;
  height: 4px;
  width: 3px;
  position: absolute;
  margin: 0;
  left: 9px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram3 {
  background-color: rgba(255, 255, 255, 0);
  top: 654px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 777px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram3 .bp3-md-grid-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram3 .bp3-md-grid-shape1 {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 10px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-instagram3 .bp3-md-grid-oval {
  background-color: rgba(255, 255, 255, 0);
  top: 4px;
  height: 3px;
  width: 2px;
  position: absolute;
  margin: 0;
  left: 14px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-grid-categorias {
  background-color: rgba(255, 255, 255, 0);
  top: 111px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-cursosonline {
  background-color: rgba(255, 255, 255, 0);
  top: 155px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-cursoseeventos {
  background-color: rgba(255, 255, 255, 0);
  top: 188px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-dvd {
  background-color: rgba(255, 255, 255, 0);
  top: 221px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-eventoscorporate {
  background-color: rgba(255, 255, 255, 0);
  top: 256px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-livro {
  background-color: rgba(255, 255, 255, 0);
  top: 292px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-audiobookmp3 {
  background-color: rgba(255, 255, 255, 0);
  top: 328px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-revenda {
  background-color: rgba(255, 255, 255, 0);
  top: 363px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-servi347os {
  background-color: rgba(255, 255, 255, 0);
  top: 437px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-saudeebemestar {
  background-color: rgba(255, 255, 255, 0);
  top: 401px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-grid-valesofertas {
  background-color: rgba(255, 255, 255, 0);
  top: 473px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(72, 72, 72, 1);
  text-align: left;
  line-height: 23px;
}

/* .anima-animate-appear prefix for appear animations */

.anima-animate-appear {
  opacity: 0;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
}

.anima-animate-disappear {
  opacity: 1;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
}

.anima-animate-nodelay {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}

@-webkit-keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.productItemDates {
  font-family: "Montserrat";
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
}

.productItemDatesSeparator {
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0px;
}

.productItemStartDate {
  text-align: right;
  padding-right: 0px;
}

.productItemEndDate {
  text-align: left;
  padding-left: 0px;
}

@media screen and (max-width: 975px) {
  .productgrid-mobile {
    display: block;
  }
  .productgrid-website {
    display: none;
  }
}

@media screen and (min-width: 975px) {
  .productgrid-mobile {
    display: none;
  }
  .bp3-md-grid {
    display: block;
  }
}

.bp3-md-grid-facebook:hover {
  transform: scale(1.15);
}

.bp3-md-grid-linkedin:hover {
  transform: scale(1.15);
}

.bp3-md-grid-youtube:hover {
  transform: scale(1.15);
}

.bp3-md-grid-instagram:hover {
  transform: scale(1.15);
}

.productGridProductContainer {
  text-align: center;
  padding-right: 0px;
  padding-left: 0px;
}

/* XS (até SM)*/

@media screen and (max-width: 575px) {
  .productItemDatesSeparator {
    display: none;
  }
  .productItemDates {
    display: block;
    text-align: center;
  }
  .productItemStartDate {
    display: block;
    text-align: center;
  }
  .productItemEndDate {
    display: block;
    text-align: center;
  }
}

/* Até MD*/

@media screen and (max-width: 767px) {
  .productCenterSpace {
    padding: 0px 0px 0px 10px;
    text-align: center;
  }
  .productItemTitle {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
  }
  .productItemRightSpace {
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .productItemCurrentPrice {
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
  }
  .productItemCartButton {
    margin-top: 7px;
  }
  .productsOrderContainer {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .categoriesButtonDisplay {
    display: block;
    /* right: 5vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) */
  }
  .categorias {
    display: none;
  }
  .productGridAddToCartButton {
    justify-content: center;
    display: flex
  }
  .productItemDates {
    display: block;
    text-align: center;
    margin-bottom: 5px;
    color: rgb(0, 123, 255);
    font-style: normal;
    font-size: 17px;
  }
  .productItemDatesSeparator {
    display: none;
  }
  .productItemStartDate {
    display: block;
    text-align: center;
    margin-bottom: 5px;
  }
  .productItemEndDate {
    display: block;
    text-align: center;
  }
}

/* Inicio de MD para cima*/

@media screen and (min-width: 768px) {
  .categoriesButtonDisplay {
    display: none;
  }
  .productItemTitle {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
  .productCenterSpace {
    padding: 0px 0px 0px 10px;
    text-align: left;
  }
  .productItemRightSpace {
    text-align: center;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .productItemCurrentPrice {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .productItemCartButton {}
  .productItemDates {
    justify-content: center;
    text-align: center;
    margin-bottom: 5px;
    color: rgb(0, 123, 255);
    font-style: normal;
    font-size: 18px;
  }
  .productsOrderContainer {
    display: flex;
    align-content: end;
    justify-content: flex-end;
    align-items: end;
    text-align: right;
  }
  .categorias {
    display: block;
  }
  .productGridAddToCartButton {
    justify-content: center;
    display: flex
  }
}

/* De lg para cima */

@media screen and (min-width: 992px) {
  .categoriesButtonDisplay {
    display: none;
  }
  .productItemTitle {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }
  .productCenterSpace {
    padding: 0px 0px 0px 10px;
    text-align: left;
  }
  .productItemRightSpace {
    text-align: right;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .productItemCurrentPrice {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .productItemCartButton {}
  .productItemDates {
    text-align: left;
    justify-content: left;
    margin-bottom: 5px;
    color: rgb(0, 123, 255);
    font-style: normal;
    font-size: 16px;
  }
  .productsOrderContainer {
    display: flex;
    align-content: end;
    justify-content: flex-end;
    align-items: end;
    text-align: right;
  }
  .categorias {
    display: block;
  }
  .productGridAddToCartButton {
    justify-content: flex-end;
  }
  .productListContainer {
    padding-left: 40px;
  }
  .productItemDates {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
  }
  .productItemStartDate {
    display: block;
    text-align: left;
    margin-bottom: 5px;
  }
  .productItemEndDate {
    display: block;
    text-align: center;
  }
  .productItemDatesSeparator {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .productItemDatesSeparator {
    display: block;
  }
}