.react-paginate ul {
    display: inline-block;
    width: 100%;
}

.react-paginate li {
    display: inline-block;
    border: 1px solid rgb(224, 224, 224);
    color: #000;
    cursor: pointer;
    margin-right: 3px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.react-paginate li a {
    padding: 4px 7px;
    display: inline-block;
    color: #007bff;
    outline: none;
}

.react-paginate li.active {
    background: rgb(224, 224, 224);
    outline: none;
}