.bp3-md-productDetail {
  min-height: 768px;
  overflow: hidden;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 1);
}

div {
  -webkit-text-size-adjust: none;
}

.bp3-md-productDetail .bp3-productphoto {
  background-color: rgba(255, 255, 255, 0);
  top: 10px;
  height: 310px;
  width: 454px;
  position: relative;
  margin: 0;
  left: 10px;
  -webkit-box-shadow: -21px -28px 48px -10px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: -21px -28px 48px -10px rgba(0, 0, 0, 0.46);
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.05);
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-productphoto .bp3-bg {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 310px;
  width: 454px;
  position: relative;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-productphoto .bp3-photo {
  background-color: rgba(255, 255, 255, 0);
  top: 132px;
  height: 46px;
  width: 93px;
  position: relative;
  margin: 0;
  left: 180px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-productprice {
  background-color: rgba(255, 255, 255, 0);
  top: 295px;
  height: auto;
  width: 72px;
  position: absolute;
  margin: 0;
  left: 521px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Roboto", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: rgba(93, 92, 94, 1);
  text-align: right;
  line-height: 26px;
}

.bp3-md-productDetail .bp3-productreviews {
  background-color: rgba(255, 255, 255, 0);
  top: 253px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 521px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Roboto", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  color: rgba(187, 191, 196, 1);
  text-align: left;
  line-height: 20px;
}

.bp3-md-productDetail .bp3-socialnetworks {
  background-color: rgba(255, 255, 255, 0);
  top: 253px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 855px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-facebook {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  right: 75px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-facebook .bp3-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-facebook .bp3-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 5px;
  position: absolute;
  margin: 0;
  left: 8px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-linkedin {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  right: 50px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-linkedin .bp3-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-linkedin .bp3-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-twitter {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  right: 25px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-twitter .bp3-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-twitter .bp3-fill1 {
  background-color: rgba(255, 255, 255, 0);
  top: 7px;
  height: 8px;
  width: 11px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-twitter .bp3-fill2 {
  background-color: rgba(255, 255, 255, 0);
  top: 9px;
  height: 4px;
  width: 3px;
  position: absolute;
  margin: 0;
  left: 9px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-addtocart {
  top: 284px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  right: 0px;
}

.bp3-md-addtocart .bp3-md-addtocartbutton {
  top: 0px;
  height: 45px;
  width: 193px;
  position: absolute;
  margin: 0;
  left: 0px;
  background: #ca006a;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  font-family: SourceSansPro-Semibold;
  font-size: 18px;
  color: #ffffff;
}

.bp3-md-addtocart .bp3-md-comprar {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: auto;
  width: 200px;
  position: absolute;
  margin: 0;
  left: 63px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  line-height: 23px;
}

.bp3-md-productDetail .bp3-whatsapp {
  background-color: rgba(255, 255, 255, 0);
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  right: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-whatsapp .bp3-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 21px;
  width: 20px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-whatsapp .bp3-shape1 {
  background-color: rgba(255, 255, 255, 0);
  top: 5px;
  height: 11px;
  width: 10px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-whatsapp .bp3-oval {
  background-color: rgba(255, 255, 255, 0);
  top: 4px;
  height: 3px;
  width: 2px;
  position: absolute;
  margin: 0;
  left: 14px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-producttitle {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: auto;
  width: 522px;
  position: absolute;
  margin: 0;
  left: 521px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Roboto", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px;
  color: rgba(93, 92, 94, 1);
  text-align: justify;
  line-height: 29px;
}

.bp3-md-productDetail .bp3-productdescription {
  background-color: rgba(255, 255, 255, 0);
  top: 63px;
  height: auto;
  width: 623px;
  position: absolute;
  margin: 0;
  right: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Roboto", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: rgba(126, 132, 140, 1);
  text-align: justify;
  line-height: 23px;
}

.bp3-md-productDetail .bp3-credits {
  background-color: rgba(255, 255, 255, 0);
  top: 203px;
  height: auto;
  width: 623px;
  position: absolute;
  margin: 0;
  right: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Roboto", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: rgba(126, 132, 140, 1);
  text-align: justify;
  line-height: 23px;
}

.bp3-productdescription {
  max-width: 66%;
  display: inherit;
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 8%;
}

.bp3-md-productDetail .bp3-btnseemore {
  background-color: rgba(255, 255, 255, 0);
  top: 384px;
  height: auto;
  /* width: 213px; */
  position: absolute;
  margin: 0;
  /* left: 577px; */
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-family: "Roboto", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: rgba(126, 132, 140, 1);
  text-align: justify;
  line-height: 19px;
}

.bp3-md-productDetail .bp3-labelsquareyes {
  background-color: rgba(255, 255, 255, 0);
  top: 253px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  right: 0px;
  border-radius: 4px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-labelsquareyes .bp3-rectangle {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-labelsquareyes .bp3-ud83dudd38color {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  overflow: hidden;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-labelsquareyes .bp3-ud83dudd38color .bp3-background {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 20px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-labelsquareyes .bp3-ud83dudd38color .bp3-stylescolorsgreen {
  background-color: rgba(89, 181, 141, 1);
  top: 0px;
  height: 21px;
  width: 113px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-productDetail .bp3-labelsquareyes .bp3-dispon355vel {
  background-color: rgba(255, 255, 255, 0);
  top: 3px;
  height: auto;
  width: 103px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Rubik", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: -0.4px;
  line-height: 14px;
}

/* .anima-animate-appear prefix for appear animations */

.anima-animate-appear {
  opacity: 0;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
}

.anima-animate-disappear {
  opacity: 1;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
}

.anima-animate-nodelay {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}

@-webkit-keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anima-reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.productDetailItemDatesCol {
  padding: 0px;
  margin-top: 5px;
}

.productDetailItemDates {
  display: flex;
  justify-content: left;
  color: rgb(0, 123, 255);
  font-weight: 400;
}

.productDetailItemStartDate {}

.productDetailDatesSeparator {
  margin-left: 5px;
  margin-right: 5px;
}

.productDetailItemEndDate {}

/* XS (até SM)*/

@media screen and (max-width: 575px) {
  .productDetailDatesSeparator {
    display: none;
  }
  .productDetailItemDates {
    display: block;
  }
  .productDetailItemStartDate {
    margin-bottom: 5px;
  }
}

/* SM (até MD)*/

@media screen and (max-width: 767px) {}

/* MD (até LG)*/

@media screen and (min-width: 768px) {}

/* LG (até XL)*/

@media screen and (min-width: 992px) {}

@media screen and (max-width: 975px) {
  .bp1-sm-show {
    display: block;
  }
  .bp3-md-show {
    display: none;
  }
}

@media screen and (min-width: 976px) {
  .bp1-sm-show {
    display: none;
  }
  .bp3-md-show {
    display: block;
  }
}

.Demo__some-network__share-button:hover {
  transform: scale(1.15);
}

.inputNotifiedEmail, .inputNotifiedEmail div input {
  font-family: "Montserrat";
  font-weight: 400;
}