@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400");

.select-css {
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 90%;
  max-width: 90%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  // -moz-appearance: none;
  // -webkit-appearance: none;
  // appearance: none;
  background-color: #fff;
  /* background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3…c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%); */
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  border: 0px;
  outline: 0px;
}

.bp3-md-menu {
  width: 100vw;
  min-width: 100vw;
  height: 100vh;
  min-height: 915px;
  position: relative;
  overflow: hidden;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 1);
}
div {
  -webkit-text-size-adjust: none;
}

.bp3-md-menu .bp3-md-menu-telephoneauricularwithcable .bp3-md-menu-path {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 28px;
  width: 28px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-wordpress {
  background-color: rgba(255, 255, 255, 0);
  top: 344px;
  height: 28px;
  width: 28px;
  position: absolute;
  margin: 0;
  left: calc(50vw - 28px / 2 - 62px / 2 - 10px);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-wordpress .bp3-md-menu-path {
  background-color: rgba(255, 255, 255, 0);
  top: 3px;
  height: 23px;
  width: 23px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-wordpress .bp3-md-menu-path1 {
  background-color: rgba(255, 255, 255, 0);
  top: 12px;
  height: 19px;
  width: 8px;
  position: absolute;
  margin: 0;
  left: 3px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-wordpress .bp3-md-menu-path2 {
  background-color: rgba(255, 255, 255, 0);
  top: 10px;
  height: 21px;
  width: 8px;
  position: absolute;
  margin: 0;
  left: 25px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-wordpress .bp3-md-menu-path3 {
  background-color: rgba(255, 255, 255, 0);
  top: 20px;
  height: 13px;
  width: 9px;
  position: absolute;
  margin: 0;
  left: 13px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-wordpress .bp3-md-menu-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 36px;
  width: 36px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-cancelamento {
  background-color: rgba(255, 255, 255, 0);
  top: 586px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  right: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}

.redes-menu-md {
  width: 100vw;
  min-width: 100vw;
  text-align: center;
  top: 724px;
}

.bp3-md-menu .bp3-md-menu-facebook {
  background-color: rgba(255, 255, 255, 0);
  height: 31px;
  width: 30px;
  margin: 0;
  bottom: 1vw;
  position: absolute;
  left: 47vw;
}
.bp3-md-menu .bp3-md-menu-facebook .bp3-md-menu-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 31px;
  width: 30px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-facebook .bp3-md-menu-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 8px;
  height: 17px;
  width: 8px;
  position: absolute;
  margin: 0;
  left: 12px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-linkedin {
  background-color: rgba(255, 255, 255, 0);
  height: 31px;
  width: 30px;
  margin: 0;
  bottom: 1vw;
  position: absolute;
  left: 49vw;
}
.bp3-md-menu .bp3-md-menu-linkedin .bp3-md-menu-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 31px;
  width: 30px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-linkedin .bp3-md-menu-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 8px;
  height: 16px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 7px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-youtube {
  background-color: rgba(255, 255, 255, 0);
  height: 31px;
  width: 30px;
  margin: 0;
  bottom: 1vw;
  position: absolute;
  left: 51vw;
}
.bp3-md-menu .bp3-md-menu-youtube .bp3-md-menu-back {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 31px;
  width: 30px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-youtube .bp3-md-menu-fill1 {
  background-color: rgba(255, 255, 255, 0);
  top: 10px;
  height: 12px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 7px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-youtube .bp3-md-menu-fill2 {
  background-color: rgba(255, 255, 255, 0);
  top: 13px;
  height: 5px;
  width: 4px;
  position: absolute;
  margin: 0;
  left: 14px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-instagram {
  background-color: rgba(255, 255, 255, 0);
  height: 31px;
  width: 30px;
  margin: 0;
  bottom: 1vw;
  position: absolute;
  left: 53vw;
}
.bp3-md-menu .bp3-md-menu-instagram .bp3-md-menu-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 31px;
  width: 30px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-instagram .bp3-md-menu-shape1 {
  background-color: rgba(255, 255, 255, 0);
  top: 7px;
  height: 16px;
  width: 16px;
  position: absolute;
  margin: 0;
  left: 7px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-instagram .bp3-md-menu-oval {
  background-color: rgba(255, 255, 255, 0);
  top: 6px;
  height: 4px;
  width: 4px;
  position: absolute;
  margin: 0;
  left: 21px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-sobren363s {
  background-color: rgba(255, 255, 255, 0);
  top: 537px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}
.bp3-md-menu .bp3-md-menu-cancelamentoegaran {
  background-color: rgba(255, 255, 255, 0);
  top: 635px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}
.bp3-md-menu .bp3-md-menu-protec347343odedados {
  background-color: rgba(255, 255, 255, 0);
  top: 586px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}
.bp3-md-menu .bp3-md-menu-loja {
  background-color: rgba(255, 255, 255, 0);
  top: 301px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: calc(50vw - 62px / 2 + 28px / 2);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}

.bp3-md-menu-entrar-div {
  width: 100vw;
}

.bp3-md-menu .bp3-md-menu-exittoappbutton1 {
  background-color: rgba(255, 255, 255, 0);
  top: 176px;
  height: 28px;
  width: 28px;
  position: absolute;
  margin: 0;
  left: calc(50vw - 28px / 2 - 62px / 2 - 10px);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-exittoappbutton1 .bp3-md-menu-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 28px;
  width: 28px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-menu-entrar {
  background-color: rgba(255, 255, 255, 0);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
  color: rgba(3, 66, 117, 1);
}
.bp3-md-menu .bp3-md-menu-registar {
  background-color: rgba(255, 255, 255, 0);
  top: 220px;
  height: auto;
  width: auto;
  position: absolute;
  left: calc(50vw - 62px / 2 + 28px / 2);
  margin: 0;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}
.bp3-md-menu .bp3-md-menu-exittoappbutton {
  background-color: rgba(255, 255, 255, 0);
  top: 220px;
  height: 28px;
  width: 28px;
  position: absolute;
  left: calc(50vw - 62px / 2 - 28px / 2 - 10px);
  margin: 0;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 28px;
  width: 28px;
  margin: 0;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.bp3-md-menu .bp3-md-menu-close1 {
  background-color: rgba(255, 255, 255, 0);
  top: 21px;
  height: 33px;
  width: 33px;
  position: absolute;
  margin: 0;
  right: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-close1 .bp3-md-menu-path {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 33px;
  width: 33px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-close1 .bp3-md-menu-path1 {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 33px;
  width: 33px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-blog {
  background-color: rgba(255, 255, 255, 0);
  top: 346px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: calc(50vw - 62px / 2 + 28px / 2);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}
.bp3-md-menu .bp3-md-menu-recursosgr341tis {
  background-color: rgba(255, 255, 255, 0);
  top: 537px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  right: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}
.bp3-md-menu .bp3-md-menu-desafiosparaaexce {
  background-color: rgba(255, 255, 255, 0);
  top: 635px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  right: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}
.bp3-md-menu .bp3-md-menu-serconsultor {
  background-color: rgba(255, 255, 255, 0);
  top: 392px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: calc(50vw - 62px / 2 + 28px / 2);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}
.bp3-md-menu .bp3-md-menu-contactos {
  background-color: rgba(255, 255, 255, 0);
  top: 435px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: calc(50vw - 62px / 2 + 28px / 2);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}

.logo-menu-md {
  width: 100vw;
  text-align: center;
}

.bp3-md-menu .bp3-md-menu-logoihtpweb05sombra1 {
  background-color: rgba(255, 255, 255, 0);
  top: 8px;
  width: 100%;
}
.bp3-md-menu .bp3-md-menu-shoppingcart1 {
  background-color: rgba(255, 255, 255, 0);
  top: 296px;
  height: 28px;
  width: 28px;
  position: absolute;
  margin: 0;
  left: calc(50vw - 28px / 2 - 62px / 2 - 10px);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-shoppingcart1 .bp3-md-menu-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 28px;
  width: 28px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-handshake {
  background-color: rgba(255, 255, 255, 0);
  top: 396px;
  height: 28px;
  width: 28px;
  position: absolute;
  margin: 0;
  left: calc(50vw - 28px / 2 - 62px / 2 - 10px);
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-handshake .bp3-md-menu-shape {
  background-color: rgba(255, 255, 255, 0);
  top: 2px;
  height: 23px;
  width: 28px;
  position: absolute;
  margin: 0;
  left: 0px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-handshake .bp3-md-menu-oval {
  background-color: rgba(255, 255, 255, 0);
  top: 9px;
  height: 2px;
  width: 2px;
  position: absolute;
  margin: 0;
  left: 30px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-handshake .bp3-md-menu-oval1 {
  background-color: rgba(255, 255, 255, 0);
  top: 9px;
  height: 2px;
  width: 2px;
  position: absolute;
  margin: 0;
  left: 5px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-handshake .bp3-md-menu-oval2 {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 2px;
  width: 2px;
  position: absolute;
  margin: 0;
  left: 8px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.bp3-md-menu .bp3-md-menu-handshake .bp3-md-menu-oval3 {
  background-color: rgba(255, 255, 255, 0);
  top: 0px;
  height: 2px;
  width: 2px;
  position: absolute;
  margin: 0;
  left: 26px;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
/* .anima-animate-appear prefix for appear animations */
.anima-animate-appear {
  opacity: 0;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
}
.anima-animate-disappear {
  opacity: 1;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
}
.anima-animate-nodelay {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}
@-webkit-keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-o-keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.bp1-xs {
  width: 100%;
  min-width: 365px;
  height: 100vh;
  min-height: 633px;
  position: relative;
  overflow: hidden;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 1);
}
a {
  text-decoration: none;
}
div {
  -webkit-text-size-adjust: none;
}
.anima-full-width-a {
  width: 100%;
}
.anima-full-height-a {
  height: 100%;
}
div {
  -webkit-text-size-adjust: none;
}
/* .anima-animate-appear prefix for appear animations */
.anima-animate-appear {
  opacity: 0;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
  animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
}
.anima-animate-disappear {
  opacity: 1;
  display: block;
  -webkit-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  -moz-animation: anima-reveal 0.3s ease-in 1 normal forwards;
  -o-animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
  animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
}
.anima-animate-nodelay {
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}
@-webkit-keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-o-keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes anima-reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bp3-md-menu-testemunhos {
  background-color: rgba(255, 255, 255, 0);
  top: 688px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}

.bp3-md-menu-tv {
  background-color: rgba(255, 255, 255, 0);
  top: 735px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  left: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}

.bp3-md-menu .bp3-md-menu-afiliados {
  background-color: rgba(255, 255, 255, 0);
  top: 688px;
  height: auto;
  width: auto;
  position: absolute;
  margin: 0;
  right: 5vw;
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-family: "Source Sans Pro", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: rgba(3, 66, 117, 1);
  text-align: right;
  line-height: 31px;
}

.bp3-md-menu-frases-poderosas {
  font-family: Source Sans Pro, Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: #034275;
  text-align: center;
  line-height: 23px;
}

@media screen and (max-width: 1365px) {
  .sobrenos {
    text-align: center;
  }
  .protecaodados {
    text-align: center;
  }
  .fundador {
    text-align: center;
  }
  .cancelamentoegarantia {
    text-align: center;
  }
  .testemunhos {
    text-align: center;
  }
  .desafios {
    text-align: center;
  }
  .noticias {
    text-align: center;
  }
  .afiliados {
    text-align: center;
  }
  .tvradio {
    text-align: center;
  }
  .frasespoderosas {
    text-align: center;
  }
}

@media screen and (min-width: 1366px) {
  .bp3-md-menu-frases-poderosas {
    background-color: rgba(255, 255, 255, 0);
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
    font-family: "Source Sans Pro", Helvetica, Arial, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    color: rgba(3, 66, 117, 1);
    text-align: right;
    line-height: 31px;
    color: rgba(3, 66, 117, 1);
  }
  .sobrenos {
    text-align: left;
  }
  .protecaodados {
    text-align: right;
  }
  .fundador {
    text-align: left;
  }
  .cancelamentoegarantia {
    text-align: right;
  }
  .testemunhos {
    text-align: left;
  }
  .desafios {
    text-align: right;
  }
  .noticias {
    text-align: left;
  }
  .afiliados {
    text-align: right;
  }
  .tvradio {
    text-align: left;
  }
  .frasespoderosas {
    text-align: right;
  }
}

.sidebarMenuTitleContainer{
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
}

.sidebarMenuItemContainer{
  display: flex;
  justify-content: center;
}

.sidebarMenuItemSubContainer{
  display:flex;
  justify-content: center;
}