@import url("https://fonts.googleapis.com/css?family=Roboto:500,400");
.md-verifyemail {
  top: 60px;
  width: 100%;
  min-width: 100vw;
  height: 100vh;
  min-height: 1024px;
  position: relative;
  overflow: hidden;
  margin: 0px;
  -webkit-font-md-verifyemailoothing: antialiased;
  -moz-osx-font-md-verifyemailoothing: grayscale;
  background-color: rgba(255, 255, 255, 1);
}

a {
  text-decoration: none;
}

div {
  -webkit-text-size-adjust: none;
}

.anima-full-width-a {
  width: 100%;
}

.anima-full-height-a {
  height: 100%;
}

div {
  -webkit-text-size-adjust: none;
}

.login-pageTitle {
  position: absolute;
  top: 15px;
  width: 100vw;
  text-align: center;
}

.md-verifyemail .back {
  top: 10px;
  left: 5vw;
  height: 22px;
  width: 19px;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.md-verifyemail .iniciarsess343o {
  height: auto;
  left: 103px;
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  font-size: 25px;
  color: rgba(71, 73, 79, 1);
  text-align: center;
}

.md-verifyemail .esquecesteapalavra {
  background-color: rgba(255, 255, 255, 0);
  top: 275px;
  height: auto;
  position: absolute;
  margin: 0;
  right: 37.5vw;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-style: normal;
  font-size: 16px;
  color: rgba(4, 67, 117, 1);
  text-align: justify;
  line-height: 21px;
}

.md-verifyemail .n343otenscontaregi {
  top: 305px;
  height: auto;
  position: absolute;
  right: 37.5vw;
  font-size: 16px;
  color: rgba(4, 67, 117, 1);
  text-align: justify;
  line-height: 21px;
}

/* Usado para email */

.md-verifyemail .textfield {
  top: 70px;
  position: absolute;
}

/* Usado para email */

.md-verifyemail .textfield .email {
  width: 25vw;
  position: absolute;
  left: 37.5vw;
}

/* Usado para password */

.md-verifyemail .textfield1 {
  top: 130px;
  position: absolute;
}

/* Usado para password */

.md-verifyemail .textfield1 .password {
  width: 25vw;
  position: absolute;
  left: 37.5vw;
}

.md-verifyemail .button {
  text-align: center;
  top: 210px;
  height: 42px;
  width: 100vw;
  position: absolute;
}

.sm-verifyemail {
  top: 60px;
  width: 100%;
  min-width: 100vw;
  height: 100vh;
  min-height: 1024px;
  position: relative;
  overflow: hidden;
  margin: 0px;
  -webkit-font-sm-verifyemailoothing: antialiased;
  -moz-osx-font-sm-verifyemailoothing: grayscale;
  background-color: rgba(255, 255, 255, 1);
}

a {
  text-decoration: none;
}

div {
  -webkit-text-size-adjust: none;
}

.anima-full-width-a {
  width: 100%;
}

.anima-full-height-a {
  height: 100%;
}

div {
  -webkit-text-size-adjust: none;
}

.justifyText {
  text-align: justify;
}

.justifyCenter {
  text-align: center;
}

.login-pageTitle {
  position: absolute;
  top: 15px;
  width: 100vw;
  text-align: center;
}

.sm-verifyemail .back {
  top: 10px;
  left: 5vw;
  height: 22px;
  width: 19px;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.sm-verifyemail .iniciarsess343o {
  height: auto;
  left: 103px;
  font-family: "Roboto", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  color: rgba(71, 73, 79, 1);
  text-align: center;
}

.sm-verifyemail .esquecesteapalavra {
  background-color: rgba(255, 255, 255, 0);
  top: 275px;
  height: auto;
  position: absolute;
  margin: 0;
  right: 15vw;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-style: normal;
  font-size: 16px;
  color: rgba(4, 67, 117, 1);
  text-align: justify;
  line-height: 21px;
}

.sm-verifyemail .n343otenscontaregi {
  top: 305px;
  height: auto;
  position: absolute;
  right: 15vw;
  font-size: 16px;
  color: rgba(4, 67, 117, 1);
  text-align: justify;
  line-height: 21px;
}

/* Usado para email */

.sm-verifyemail .textfield {
  top: 70px;
  position: absolute;
}

/* Usado para email */

.sm-verifyemail .textfield .email {
  width: 70vw;
  position: absolute;
  left: 15vw;
}

/* Usado para password */

.sm-verifyemail .textfield1 {
  top: 130px;
  position: absolute;
}

/* Usado para password */

.sm-verifyemail .textfield1 .password {
  width: 70vw;
  position: absolute;
  left: 15vw;
}

.sm-verifyemail .button {
  text-align: center;
  top: 210px;
  height: 42px;
  width: 100vw;
  position: absolute;
}

.xs-verifyemail {
  top: 60px;
  width: 100%;
  min-width: 100vw;
  height: 100vh;
  min-height: 633px;
  position: relative;
  overflow: hidden;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(255, 255, 255, 1);
}

a {
  text-decoration: none;
}

div {
  -webkit-text-size-adjust: none;
}

.anima-full-width-a {
  width: 100%;
}

.anima-full-height-a {
  height: 100%;
}

div {
  -webkit-text-size-adjust: none;
}

.login-pageTitle {
  position: absolute;
  top: 15px;
  width: 100vw;
  text-align: center;
}

.xs-verifyemail .back {
  top: 10px;
  left: 5vw;
  height: 22px;
  width: 19px;
  position: absolute;
  margin: 0;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}

.xs-verifyemail .iniciarsess343o {
  height: auto;
  left: 103px;
  font-family: "Roboto", Helvetica, Arial, serif;
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  color: rgba(71, 73, 79, 1);
  text-align: center;
}

.xs-verifyemail .esquecesteapalavra {
  background-color: rgba(255, 255, 255, 0);
  top: 275px;
  height: auto;
  position: absolute;
  margin: 0;
  right: 5vw;
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
  font-style: normal;
  font-size: 16px;
  color: rgba(4, 67, 117, 1);
  text-align: justify;
  line-height: 21px;
}

.xs-verifyemail .n343otenscontaregi {
  top: 305px;
  height: auto;
  position: absolute;
  right: 5vw;
  font-size: 16px;
  color: rgba(4, 67, 117, 1);
  text-align: justify;
  line-height: 21px;
}

/* Usado para email */

.xs-verifyemail .textfield {
  top: 70px;
  position: absolute;
}

/* Usado para email */

.xs-verifyemail .textfield .email {
  width: 90vw;
  position: absolute;
  left: 5vw;
}

/* Usado para password */

.xs-verifyemail .textfield1 {
  top: 130px;
  position: absolute;
}

/* Usado para password */

.xs-verifyemail .textfield1 .password {
  width: 90vw;
  position: absolute;
  left: 5vw;
}

.xs-verifyemail .button {
  text-align: center;
  top: 210px;
  height: 42px;
  width: 90vw;
  position: absolute;
  left: 5vw;
}

@media screen and (max-width: 767px) {
  .xs-verifyemail {
    display: block;
  }
  .sm-verifyemail {
    display: none;
  }
  .md-verifyemail {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .xs-verifyemail {
    display: none;
  }
  .sm-verifyemail {
    display: block;
  }
  .md-verifyemail {
    display: none;
  }
}

@media screen and (min-width: 1366px) {
  .xs-verifyemail {
    display: none;
  }
  .sm-verifyemail {
    display: none;
  }
  .md-verifyemail {
    display: block;
  }
}