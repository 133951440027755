.footerPartner {
    margin-top: 20px;
    padding: 0px 10px 0px 10px;
}

.footerPartner hr {
    border-color: white;
    margin-bottom: 20px;
}

.footerPartner .footerMenuItem {
    padding: 0px;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
}

.footerPartnerCol {
    margin: 0px;
    padding: 0px;
    max-width: 25%;
    min-width: 25%;
}

.footerPartnerCol img {
    vertical-align: middle;
}

.footerPartnerColumnsContainer {
    margin: 0px;
    padding: 0px;
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
    .footerPartnerCol {
        max-width: 25%;
        min-width: 25%;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .footerPartnerCol {
        max-width: 18%;
        min-width: 18%;
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .footerPartnerCol {
        max-width: 18%;
        min-width: 18%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .footerPartnerCol {
        max-width: 15%;
        min-width: 15%;
    }
}