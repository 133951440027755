.textFieldFontStyle, .textFieldFontStyle input, .textFieldFontStyle label {
    font-family: "Montserrat", sans-serif!important;
    font-weight: 500;
}

.registerTitleParent {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
}

.registerTitle {
    font-family: "Montserrat", sans-serif!important;
    font-weight: 600;
    font-style: normal;
    font-size: 25px;
    color: rgba(71, 73, 79, 1);
}

.registerContainer {
    width: 100%;
    margin: auto
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .registerContainer {
        width: 75%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {}